import React from "react"
import { useDispatch } from 'react-redux'
import Layout from "../components/layoutLogin"
import Register from "../components/register"
import SEO from "../components/seo"
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { cleanRegister } from '../reducers/actions/user.js'

const RegisterPage = () => {
  const registerState = useSelector(state => (state.userReducer.register))
  const dispatch = useDispatch()

  if(registerState.response) {
    cleanRegister(dispatch)
    navigate('/end-register/')
  }
 
  return (
    <Layout>
      <SEO title="Registro" />
      <Register/>
    </Layout>
  )
}

export default RegisterPage
