import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as styles from './styles.module.css'
import { register } from '../../reducers/actions/user.js'
import { Link } from "gatsby"
import { pipe, cond, T, tap } from 'ramda'
import { isValidEmail, isValidPassword } from '../../utils/common.js'

const Register = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const registerState = useSelector(state => (state.userReducer.register))
  
  const getTargetValue = fn => event => fn(event.target.value)
  const dispatch = useDispatch()

  const registerRequest = () =>  pipe(
    tap(() => setError('')),
    cond([
      [() => !isValidEmail(email), () => setError('Email no válido')],
      [() => !isValidPassword(password), () => setError('La contraseña debe ser mínimo de 8 caracteres')], 
      [() => (password !== confirmPassword), () => setError('Las contraseña no coinciden')],
      [T, register(dispatch)]
    ])
  )({email, password})

   const getError = () => {
    if(error.length > 0) {
      return error
    }
    if(registerState.errorMsg.length > 0) {
      return registerState.errorMsg
    }
    return ''
  }


  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div>
          <img src={'https://picsum.photos/450/450'}/>
        </div>
        <div className={styles.form}>
          <h1>Registro</h1>
          
          <input value={email} placeholder={'Email'} onChange={getTargetValue(setEmail)} />
          <input value={password} placeholder={'Contraseña'} type='password' onChange={getTargetValue(setPassword)}/>
          <input value={confirmPassword} placeholder={'Repetir contraseña'} type='password' onChange={getTargetValue(setConfirmPassword)}/>
          
          <button
            className={'primary'}
            style={{width: '100%'}}
            onClick={registerRequest}
            disabled={!!registerState.loading}
          >
            Registrarme
          </button>
          <p>{getError()}</p>
          <div className={styles.accountOptions}>
            <Link to={'/login'}>Volver al login</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
